.home-container {
    margin-left: 15%;
    margin-right: 15%;
    justify-content: center;
}
.margin-top {
    margin-top: 5%;
}
.work-card {
    height: "auto";
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin: 0.2rem;
}
@media only screen and (max-width: 600px) {
    .work-card {
        height: 100%;
        width: "auto";
    }
}
.image {
    height: 100%;
    width: 100%;
}
.button-text {
    width: 100%;
    margin-bottom: 1rem;
}

.button-text-code {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.slider-btn-left {
    position: absolute;
    top: 30%;
    left: 5%;
}

.slider-btn-right {
    position: absolute;
    top: 30%;
    right: 5%;
}
image-modal-container {
    width: 80%;
    height: 50%;
    overflow: none;
}
.modal-image {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.slide-active {
    display: flex;
}

.slide {
    display: none;
}
.modal-image-container {
    height: 400px;
    width: 700px;
    border-style: solid;
    boder-color: #000;
    margin-top: 5rem;
    margin-left: 20rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mtloading {
    margin-top: 30%;
}

.work-des {
    padding-left: 2rem;
}
.work-title {
    font-size: 1.3rem;
    margin-top: 2rem;
}
.work-description {
    font-size: 0.7;
}

.view-code {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

@media only screen and (max-width: 1566px) {
    .modal-image-container {
        height: 500px;
        width: 600px;
        border-style: solid;
        margin-top: 5rem;
        boder-color: #000;
        margin-left: 15rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1265px) {
    .modal-image-container {
        height: 300px;
        width: 350px;
        border-style: solid;
        margin-top: 5rem;
        boder-color: #000;
        margin-left: 15rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .view-code {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-left: 15rem;
    }
}

@media only screen and (max-width: 950px) {
    .modal-image-container {
        height: 300px;
        width: 350px;
        border-style: solid;
        margin-top: 5rem;
        boder-color: #000;
        margin-left: 10rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .work-title {
        font-size: 1.3rem;
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 800px) {
    .modal-image-container {
        display: none;
    }

    .view-code {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-left: 8rem;
    }

    .work-des {
        padding-left: 5rem;
    }
    .slider-btn-left {
        display: none;
    }
    .slider-btn-right {
        display: none;
    }
    .work-title {
        margin-top: 10rem;
    }
}

@media only screen and (max-width: 600px) {
    .work-title {
        font-size: 12px;
    }
}
