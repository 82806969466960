.modal-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal {
  border-radius: 1%;
  position: relative;
  background-color: white;
  width: 75%;
  display: flex;
  overflow: scroll;
}
.modal-short {
  height: 60%;
}
.modal-success {
  height: 20%;
  width: 50%;
}
.modal-long {
  height: 90%;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
