.home-container {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  justify-content: center;
}
.resume {
  display: flex;
  text-align: flex-start;
  width: fit-content;
  margin-top: 2rem;
}
