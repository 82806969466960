.home-container {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    justify-content: center;
}
.contact-me {
    margin-top: 2%;
    width: 80%;
}
.label-contact {
    display: flex;
    align-items: flex-start;
    width: 20%;
    margin-top: 2%;
}
.input-box {
    margin-bottom: 5%;
}
.button {
    margin-top: 5%;
    display: flex;
    align-items: flex-start;
}
